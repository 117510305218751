<!--수동딜 관리 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="flex">
				<div class="w-1/9">
					<h6>딜 상태</h6>
					<vSelect v-model="opt.isComplete" :clearable="false" :options="isCompleteOpts" />
				</div>
				<div class="w-1/9 ml-3">
					<h6>작성일</h6>
					<datePicker model="opt.startRequestDate" />
					<h6 class="block text-center m-1">~</h6>
					<datePicker model="opt.endRequestDate" />
				</div>

				<div v-show="isUseAcceptDay" class="w-1/9 ml-3">
					<h6>등록일</h6>
					<datePicker :ths="this" model="opt.startDealAcceptDay" />
					<h6 class="block text-center m-1">~</h6>
					<datePicker :ths="this" model="opt.endDealAcceptDay" />
				</div>
				<div class="w-1/9 ml-3">
					<h6>협찬품</h6>
					<vSelect v-model="opt.productOption" :clearable="false" :options="productOptionOpts" />
				</div>
				<div class="w-1/2 absolute right-2 flex">
					<div class="w-2/9">
						<h6>키워드 검색</h6>
						<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
					</div>
					<div class="w-4/7 mx-2">
						<h6>&nbsp;</h6>
						<div>
							<b-form-input
								class=""
								v-model.trim="opt.searchKeyword"
								name="keyword"
								placeholder="검색어"
								@keyup.enter="search"
							/>
						</div>
					</div>
					<div class="w-1/7">
						<h6>&nbsp;</h6>
						<b-button class="w-1/1" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<div class="mb-2">
				<b-button @click="testMultiDeal" variant="purple">일괄 등록</b-button>
				<b-button class="ml-4" @click="deleteTemporaryDeals(tb.getChecked(), false)" variant="gray"
					>일괄 삭제
				</b-button>
				<b-button class="absolute right-0" @click="page('manualDealAdd', { idx: 'def' })">딜 작성</b-button>
			</div>
			<tb :inf="inf" :res="res" limit="20" />
		</b-card>
	</div>
</template>

<script>
import manualDealMixIn from 'pages/manualDealMixIn'
import manualDealSearchCard from 'pages/manualDealAddSearchCard'

const now = new Date(),
	isCompleteOpts = [
		{ label: '전체', value: '' },
		{ label: '임시저장', value: 'T' },
		{ label: '등록완료', value: 'O' },
	],
	productOptionOpts = [
		{ label: '전체', value: '' },
		{ label: '협찬품 있음', value: 'option' },
		{ label: '협찬품 없음', value: 'noOption' },
		{ label: '협찬품 선택중', value: 'choseOption' },
	],
	searchKeyOpts = [
		{ label: '딜번호', value: 'openDealNum' },
		{ label: '인스타아이디', value: 'currentInstaId' },
		{ label: '이름', value: 'memberName' },
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '브랜드명', value: 'brand' },
		{ label: '상품명', value: 'productName' },
	],
	defOpt = {
		startRequestDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30),
		endRequestDate: now,
		startDealAcceptDay: '',
		endDealAcceptDay: '',
		isComplete: isCompleteOpts[0],
		productOption: productOptionOpts[0],
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
		isShowAcceptDate: true,
	}

export default {
	mixins: [manualDealMixIn],
	data() {
		return {
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},

			isCompleteOpts,
			productOptionOpts,
			searchKeyOpts,

			inf: [
				{ model: 'checkAll', vIf: v => v.isTemporary },
				{ title: 'seq', model: 'idx', size: 2 },
				{ title: '딜번호', key: 'openDealNum', size: 6 },
				{ title: '인스타 아이디', key: 'currentInstaId', size: 8 },
				{ title: '이름', key: 'memberName', size: 6 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '브랜드명', key: 'brandName', size: 7 },
				{ title: '상품명', key: 'productName', size: 16 },
				{ title: '협찬품', key: 'productOption', size: 10 },
				{ title: '신규/앵콜', key: 'dealType', size: 6 },
				{ title: '딜 시작일', key: 'startDate', size: 6 },
				{ title: '딜 종료일', key: 'endDate', size: 6 },
				{ title: '딜 상태', key: 'isComplete', size: 5 },
				{ title: '작성일', key: 'requestDate', size: 6 },
				{ title: '등록일', key: 'dealAcceptDay', size: 6 },
				//등록/수정/삭제/메모 버튼
				{
					title: '등록',
					model: 'button',
					size: 4,
					vIf: this.isTemporaryDeal,
					btnSize: 'sm',
					text: '등록',
					func: this.testSingleDeal,
				},
				{
					title: '수정/삭제',
					model: 'editDelete',
					size: 6,
					vIf1: this.isTemporaryDeal,
					func1: this.editDeal,
					vIf2: this.isTemporaryDeal,
					func2: this.deleteTemporaryDeals,
				},
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 4 },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const options = this.opt,
				isComplete = getDropdownValue(options.isComplete),
				startRequestDate = getDateValue(options.startRequestDate),
				endRequestDate = getDateValue(options.endRequestDate, false),
				startDealAcceptDay = getDateValue(options.startDealAcceptDay),
				endDealAcceptDay = getDateValue(options.endDealAcceptDay, false),
				productOption = getDropdownValue(options.productOption),
				searchKeyName = getDropdownValue(options.searchKeyName),
				searchVal = options.searchKeyword

			let data = { paging: getPaging(0, 20) },
				//선택 조건에 없는 필수값들 입력
				error = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (isComplete) data.isComplete = isComplete

			if (startRequestDate && endRequestDate) {
				if (startRequestDate > endRequestDate) error.push('작성일의 시작일이 종료일보다 큽니다.')
				else {
					data.startRequestDate = startRequestDate
					data.endRequestDate = endRequestDate
				}
			}

			if (this.isUseAcceptDay && startDealAcceptDay && endDealAcceptDay) {
				if (startDealAcceptDay > endDealAcceptDay) error.push('등록일의 시작일이 종료일보다 큽니다.')
				else {
					data.startDealAcceptDay = startDealAcceptDay
					data.endDealAcceptDay = endDealAcceptDay
				}
			}

			if (productOption) data.productOption = productOption

			if (searchKeyName && searchVal)
				data.searchKey = [
					{
						fieldName: searchKeyName,
						andOr: 'and',
						operation,
						value: searchVal,
					},
				]
			if (error.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				if (this.table) this.tb.clearChecked()
				return this.changePage(1)
			}
		},
		changePage(page = 1) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/dealSelect/manualDealList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					;['startDate', 'endDate', 'requestDate', 'dealAcceptDay'].map(k => {
						v[k] = v[k] ? v[k].substr(0, 10) : ''
					})

					if (v.isComplete == 'T') {
						v.isComplete = '임시저장'
						v.isTemporary = true
						v.trClass = 'table-danger'
					} else {
						v.isComplete = '등록완료'
						v.isTemporary = false
					}

					if (!v.productOption || v.productOption == '협찬품 선택중')
						v.productOptionOpt = { tdClass: 'text-danger' }
					return v
				})

				this.res = res
			})
		},
		testSingleDeal(item) {
			//임시 저장 딜 단건 등록. 중복검사 하고 아래 acceptTemporaryDeal로 넘김
			confirm('해당 임시저장 딜을 실제 등록하시겠습니까?', () => {
				postApi('/dealSelect/isDuplicatedManualDeal', [
					{
						hottId: item.hottId,
						startDate: item.startDate + 'T00:00:00',
						productGroupIdx: item.productGroupIdx,
					},
				]).then(dup => {
					if (dup.length) {
						//중복된 항목이 있을 때 등록할지 물어보는 confirm
						confirm(
							`${dup[0].message}\r\n-딜 번호 : ${dup[0].dealNum}\r\n-일정 : ${dup[0].startDate.substr(
								5,
								5
							)} ~ ${dup[0].endDate.substr(5, 5)}\r\n-협찬품 : ${
								dup[0].productOptionName
							}\r\n※ 앵콜딜을 바로등록 하시겠습니까?`,
							() => {
								this.acceptTemporaryDeal([item], true)
							}
						)
					} else this.acceptTemporaryDeal([item], true)
				})
			})
		},
		testMultiDeal() {
			//임시 저장 딜 일괄 등록. 확인 메시지 띄운 뒤 acceptTemporaryDeal로 넘김
			const item = this.tb.getChecked()
			if (item.length)
				confirm('선택된 임시저장 딜을 일괄 등록 하시겠습니까?', () => {
					this.acceptTemporaryDeal(item, false)
				})
			else alert.w('선택한 임시저장 딜이 없습니다.')
		},
		editDeal(item) {
			page('manualDealAdd', { idx: item.idx })
		},
		deleteTemporaryDeals(item, isUnity = true) {
			//임시 저장 딜 삭제 처리. 일괄일 수도 있고 단건일 수도 있음
			if (isUnity) item = [item]
			if (item.length) {
				const confirmText = isUnity
					? '해당 임시저장 딜을 삭제하시겠습니까?'
					: '선택된 임시저장 딜을 일괄 삭제 하시겠습니까?\r\n삭제된 내용은 복구할 수 없습니다!'
				confirm(confirmText, () => {
					putApi(
						'/dealHandle/deleteTemporaryDeals',
						{ dealList: item.map(v => v.idx), adminSeq: layout.user.seqNo },
						true,
						false
					).then(result => {
						const res = result.data,
							success = res.success,
							memoFailedMsg = this.getSideFailedMsg(res, isUnity)
						if (success && success.length == item.length) {
							if (memoFailedMsg) alert.w(memoFailedMsg)
							else alert.s('딜 삭제 완료되었습니다')
						} else {
							let error =
								'딜 삭제 완료되었으며, 아래 사유에 따라 일부 딜이 정상 삭제되지 않았습니다.\r\n' +
								this.getFailedMsg(res, isUnity) +
								'\r\n확인 후 해당 딜은 다시 시도해주세요. (번호는 seq 기준입니다.)'
							if (memoFailedMsg) error += '\r\n\r\n' + memoFailedMsg
							alert.w(error)
						}
						this.changeResState(success, true)
					})
				})
			} else alert.w('선택된 항목이 없습니다')
		},
		isTemporaryDeal(item) {
			return item.isTemporary
		},
		changeResState(successArr = [], isDelete = true) {
			//임시 저장한 수동딜을 등록/삭제한 뒤 목록에 있는 레코드의 상태를 수정한다.
			//그냥 다시 불러오는 걸로 수정
			this.changePage(this.lastOpt.paging.pageNo + 1)
		},
	},
	computed: {
		isUseAcceptDay() {
			return this.opt.isComplete && this.opt.isComplete.value == 'O'
		},
	},
	created() {
		this.search()
	},
}
</script>

<style>
.vs-dialog-text {
	padding-top: 0 !important;
	white-space: pre-line !important;
}
</style>
